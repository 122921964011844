import get from "lodash/get";
import { Img } from "wmk-image";
// import { Img } from "./Img";
import { MenuHierarchy } from "./Menu";
import { getHashTable } from "./Page";
// import { RichText } from "./RichText";
import { RichText } from "wmk-rich-text";
import { ResourceQuery } from "../fragments/NodeResourceFields";

/**
 * @class - represents a single ContentfulResource node
 */
export class Resource {
  /**
   *
   * @param {Object} node - NodeResourceFields result
   */
  title: string;
  serpTitle: string;
  parentPath: string;
  parent: string;
  slug: string;
  meta: string;
  subtext: string;
  content: RichText;
  featuredImage: Img;
  secondaryImage: Img;
  ogImage: string;
  twitterImage: string;
  hierarchy: MenuHierarchy;
  constructor(node: ResourceQuery) {
    const _node = { ...node };
    // console.log("node", node);
    // console.log("_node", _node);
    const parent = _node.parentPath;
    const slug = _node.slug;
    const content = _node.content ? new RichText(_node.content) : null;
    this.title = _node.title;
    this.serpTitle = _node.serpTitle;
    this.parentPath =
      _node.technicalResourceOrNewsroomPage === "Newsroom Page"
        ? `/media-newsroom${parent}`
        : `/technical-resources${parent}`;
    this.parent = parent;
    this.slug = slug;
    this.meta = this.meta = _node.meta?.description
      ? _node.meta?.description
      : content
      ? content.excerpt(155)
      : _node.sub?.text;
    this.subtext = _node.sub?.text;
    this.content = content;
    this.featuredImage = new Img(_node.featuredImage);
    this.secondaryImage = new Img(_node.secondaryImage);
    this.ogImage = _node.ogImage?.gatsbyImageData.images.fallback.src;
    this.twitterImage = _node.twitterImage?.gatsbyImageData.images.fallback.src;
    // console.log(
    //   `parent.split("/").slice(1, parent.split("/").length)`,
    //   parent.split("/").slice(1, parent.split("/").length)
    // );
    // console.log("parent", parent);
    this.hierarchy = new MenuHierarchy(
      parent !== "/"
        ? [
            _node.technicalResourceOrNewsroomPage === "Newsroom Page"
              ? `media-newsroom`
              : `technical-resources`,
            ...parent.split("/").slice(1, parent.split("/").length),
            slug
          ]
        : [
            _node.technicalResourceOrNewsroomPage === "Newsroom Page"
              ? `media-newsroom`
              : `technical-resources`,
            slug
          ]
    );
  }
}

/**
 * @class - represents a collection of ContentfulResource nodes
 */
export class Resources {
  /**
   *
   * @param {Array} edges Array of NodeResourceFields results
   */
  list: Resource[];
  length: number;
  slugHash: { [key: string]: Resource[] };
  constructor(edges) {
    const pgs = Array.isArray(edges) ? edges : [];
    const pages = pgs.map((edge) => {
      const newPage = new Resource(edge.node);
      return newPage;
    });
    this.list = pages;
    this.length = pages.length;
    this.slugHash = getHashTable(pages, `slug`);
  }
  /**
   *
   * @param {string} slug
   * @returns {array} Array of matching objects
   */
  matchParent(slug) {
    const all = this.list;
    return all.reduce((match, pg) => {
      if (pg.parent === slug) {
        match.push(pg);
      }
      return match;
    }, []);
  }
  /**
   *
   * @param {Object} pgs Resources object
   * @returns {Resources} object with combined data
   */
  append(pgs: Resources) {
    if (pgs instanceof Resources) {
      this.list = this.list.concat(pgs.list);
      this.slugHash = getHashTable(this.list, `slug`);
      this.length++;
    }
    return this.list;
  }
  /**
   *
   * @param {string} slug
   * @returns Page object that matches slug
   */
  matchSlug(slug) {
    return get(this, `slugHash[${slug}]`);
  }
}
