import { graphql } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Pages } from "../classes/Page";
import { Resources } from "../classes/Resource";
import get from "lodash/get";
import { Type } from "../components/ui/Typography";
import { HyperLink } from "../classes/HyperLink";
import { WmkLink } from "wmk-link";
import styled from "styled-components";
import { colors } from "../vars/palette";
import { WmkSeo } from "wmk-seo";

const classHash = {
  pages: Pages,
  resources: Resources
};

const SitemapLink = styled(Col)`
  a {
    color: ${colors.blue.hex};
    transition: all 0.3s ease;
    &:hover {
      text-decoration: none;
      transition: all 0.3s ease;
    }
  }
`;

const SitemapPage = ({ data }) => {
  const map = Object.keys(data).map((key) => {
    const Cls = get(classHash, `[${key}]`);
    if (Cls) {
      return { key, class: new Cls(get(data, `[${key}].edges`, [])) };
    } else {
      return null;
    }
  });
  return (
    <Container style={{ padding: `6vh 3vw` }}>
      <WmkSeo.Meta
        title="Sitemap"
        slug="sitemap"
        baseUrl="https://dipra.org"
        siteTitle="DIPRA"
        twitterHandle="@DIPRA"
        twitterImageUrl={""}
        ogImageUrl={""}
      />
      <h1>Sitemap</h1>
      {map.map((edge, i) => {
        const name = get(edge, `key`);
        const list = get(edge, `class.list`, []);
        list.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
        return (
          <Row
            className="flex-column"
            key={name + i}
            style={{ padding: `2vh 3vw` }}>
            <Type.H2 style={{ color: colors.grayBlue.hex }}>{name}</Type.H2>
            {list.map((node, i) => {
              const link = new HyperLink(node);
              return !link.text.match(/schema/i) ? (
                <SitemapLink key={link.to + i}>
                  <WmkLink
                    to={
                      link.to ===
                      "/technical-resources/calculators/life-cycle-cost-analysis"
                        ? `/member-section/lcca-calculator`
                        : "/home"
                        ? "/"
                        : link.to
                    }
                    target={link.target}>
                    {link.text}
                  </WmkLink>
                </SitemapLink>
              ) : null;
            })}
          </Row>
        );
      })}
    </Container>
  );
};

export default SitemapPage;

export const query = graphql`
  {
    pages: allContentfulPage(
      filter: {
        slug: {
          regex: "/^(?!.*thank-you).*/"
          nin: [
            "conference"
            "schema"
            "print-ad-bank-2022"
            "digital-ad-bank-2022"
            "resource-shelf"
            "results"
            "test"
            "algoapi"
          ]
        }
      }
    ) {
      edges {
        node {
          ...NodePageFields
        }
      }
    }
    resources: allContentfulResource {
      edges {
        node {
          ...NodeResourceFields
        }
      }
    }
  }
`;
